import React from 'react'

import styles from './OrderTotal.module.css'

interface OrderTotalProps {
  subtotal: number;
  hst: number;
  containerWidth: string;
}

function OrderTotal({ subtotal, hst, containerWidth }: OrderTotalProps) {
	const parseMoney = (cents: number): string => {
		return `$${(cents / 100).toFixed(2)} CAD`
	}
  
	return (
		<div className={styles.container} style={{ width: containerWidth }}>
			<div className={styles.row}>
				<div className={styles.title}>Subtotal:</div>
				<div className={styles.value}>{parseMoney(subtotal)}</div>
			</div>
			<div className={styles.row}>
				<div className={styles.title}>HST:</div>
				<div className={styles.value}>{parseMoney(hst)}</div>
			</div>
			<div className={styles.row}>
				<div className={styles.title}>Final Total:</div>
				<div className={styles.value}>{parseMoney(subtotal + hst)}</div>
			</div>
		</div>
	)
}

export default OrderTotal
