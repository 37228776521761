import React from 'react'
import { StoreCartItem } from '../../../../commonTypes/StoreTypes'
import useCart from '../../contexts/useCart'

import styles from './CartItem.module.css'
  
interface CartItemProps {
	item: StoreCartItem,
	index: number
}

function CartItem({ item, index }: CartItemProps) {
	const { removeItemAtIndex } = useCart()

	// const parsedOptions = useMemo(() => {
	// 	const ops: string[] = []

	// 	if (item.variant.option1) {
	// 		ops.push(item.variant.option1)
	// 	}

	// 	if (item.variant.option2) {
	// 		ops.push(item.variant.option2)
	// 	}

	// 	if (item.variant.option3) {
	// 		ops.push(item.variant.option3)
	// 	}

	// 	return ops.length ? ops.join(', ') : ''
	// }, [items])
	
	const deleteHandler = () => removeItemAtIndex(index)

	
	return (
		<div className={styles.container}>
			<div className={styles.itemInfo}>
				<img className={styles.image} src={item.product.image?.src} />
				<div>
					<div className={styles.name}>{item.name}</div>
					{/* {parsedOptions && <div className={styles.options}>{parsedOptions}</div>} */}
					<div className={styles.price}>{item.variant.price}</div>
				</div>
			</div>
			<div className={styles.removeBtn} onClick={deleteHandler}>&#10005;</div>
		</div>
	)
}
  
export default CartItem
