import React from 'react'

import styles from './Breadcrumb.module.css'

interface BreadcrumbProps {
  path: string[];
}

function Breadcrumb({ path }: BreadcrumbProps) {
	return (
		<div className={styles.container}>
			{path.map((pathName) => {
				return <div className={styles.crumb} key={pathName}>{pathName}</div>
			})}
		</div>
	)
}

export default Breadcrumb
