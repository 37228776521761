import React from 'react'

import { faCheck } from '@fortawesome/free-solid-svg-icons'

import styles from './Selector.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface Option {
  name: string;
  value: string | number;
  disabled: boolean;
	color?: string;
	min?: number;
	max?: number;
	restrictions?: string[];
  selected: boolean;
	suffixImages?: string[];
}

interface SelectorProps {
  options: Option[];
  setOptions: React.Dispatch<Option[]>
	radio?: boolean;
}

function Selector({ setOptions, options, radio }: SelectorProps) {

	const toggleOption = ({ value, max, selected }: Option) => {
		const selectedOptions = options.filter(({ selected }) => selected)
		if (max && selectedOptions.length + 1 > max && !selected && !radio) return
	
		if (radio) return setOptions(options.map((option) => ({ ...option, selected: option.selected ? false : option.value === value })))
		else setOptions(options.map((option) => value === option.value ? { ...option, selected: !option.selected } : option))
	}
  
	return (
		<>
			{options.map((option, index) => {
				return (
					<div key={index} className={styles.option} onClick={() => !option.disabled && toggleOption(option)}>
						<div className={`${styles.selector} ${radio ? styles.radio : ''} ${option.selected ? styles.selected : ''} ${option.disabled ? styles.disabled : ''}`}>
							<div className={styles.check}>
								<FontAwesomeIcon icon={faCheck} />
							</div>
						</div>
						<div className={styles.label}>{ option.name }</div>
						{option.suffixImages?.map((src) => {
							return <img key={src} src={src} className={styles.optionIcon} />
						})}
					</div>
				)
			})}
		</>
	)
}

export default Selector
