import React from 'react'

import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

import styles from './Navigation.module.css'

import Anchor, { AnchorProps } from '../Anchor/Anchor'
import classes from '../../utils/classes'

const links: AnchorProps[] = [
	{
		label: 'Products',
		href: '/',
	},
	{
		label: 'Bio',
		href: `${process.env.REACT_APP_STATIC_URL}/bio`,
	},
	{
		label: 'Events',
		href: `${process.env.REACT_APP_STATIC_URL}/#events`
	},
	{
		label: 'Contact Us',
		href: `${process.env.REACT_APP_STATIC_URL}/#contact`
	},
	{
		label: 'Place An Order',
		href: '/',
		highlight: true,
	},
	{
		icon: faFacebook,
		href: 'https://www.facebook.com/jesa_sweet_tooth-1137786129757062',
		button: true,
	},
	{
		icon: faInstagram,
		href: 'https://www.instagram.com/jesa_sweet_tooth/',
		button: true,
	},
]

interface NavigationProps {
  expanded?: boolean;
}

export function MobileNavigation() {
	return (
		<nav className={styles.mobileLinks}>
			<div className={styles.mobileRow}>
				{links
					.filter(({ highlight, button }) => !highlight && !button)
					.map((options) => {
						return <Anchor key={`${options.label}${options.href}`} {...options} />
					})}
			</div>
			<div className={styles.mobileRow}>
				{links
					.filter(({ highlight }) => highlight)
					.map((options) => {
						return <Anchor key={`${options.label}${options.href}`} {...options} />
					})}
				<div className={styles.buttons}>
					{links
						.filter(({ button }) => button)
						.map((options) => {
							return <Anchor key={`${options.label}${options.href}`} {...options} />
						})}
				</div>
			</div>
		</nav>
	)
}

export function Navigation({ expanded = false }: NavigationProps) {
	return (
		<nav
			className={classes(styles.links, {
				[styles.expanded]: expanded,
			})}
		>
			{links
				.filter(({ button }) => !button)
				.map((options) => {
					return <Anchor key={`${options.label}${options.href}`} {...options} />
				})}
			<div className={styles.buttons}>
				{links
					.filter(({ button }) => button)
					.map((options) => {
						return <Anchor key={`${options.label}${options.href}`} {...options} />
					})}
			</div>
		</nav>
	)
}

export default Navigation
