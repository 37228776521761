import React, { useContext, createContext, useEffect, useState } from 'react'
import { Product } from '../../../commonTypes/StoreTypes'
import { getProducts } from '../api/routes/products'

const Context = createContext<{
	isLoading: boolean,
	products: Product[],
}>(null as any)


interface ProviderProps{
	children:any
}

export const ProductsProvider = ({ children }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(true)
	const [products, setProducts] = useState<Product[]>([])
	
	useEffect(() => {
		getProducts().then(newProducts => {
			setProducts(newProducts)
			setIsLoading(false)
		})
	}, [])

	return <Context.Provider value={{
		isLoading,
		products
	}}>
		{ children }
	</Context.Provider>
}

const useProducts = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useProducts outside provider!')
	}
	return val
}

export default useProducts
