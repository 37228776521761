import React from 'react'

import styles from './CustomerInfo.module.css'

interface CustomerInfoProps {
  title: string;
  data: string[];
}

const CustomerInfo = ({ title, data }: CustomerInfoProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.title}>{title}</div>
			<div className={styles.data}>
				{data.map((information) => {
					return (
						<div key={information} className={styles.dataPiece}>
							{information}
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default CustomerInfo
