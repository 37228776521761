import React from 'react'
import { Product } from '../../../../commonTypes/StoreTypes'
import Popup from '../Popup/Popup'
import s from './AddToCartPopup.module.css'
import MultiOption from './MultiOption'
import OneOption from './OneOption'

interface Props{
	product: Product
	visible: boolean,
	setVisible(v:boolean): void
}

export default function AddToCartPopup({ product, visible, setVisible }:Props) {
	return (
		<Popup visible={visible} setVisible={setVisible} title={product.title} className={s.AddToCartPopup}>
			<div className={s.image}>
				<img src={product.image?.src} alt="product" />
			</div>
			
			{product.options.length === 1 && <OneOption product={product} setVisible={setVisible}/>}
			{product.options.length > 1 && <MultiOption product={product} setVisible={setVisible}/>}
		</Popup>
	)
}
