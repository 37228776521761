import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Anchor from '../../components/Anchor/Anchor'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import Header from '../../components/Header/Header'
import Input from '../../components/Input/Input'
import useCart from '../../contexts/useCart'
import { useWindowSize } from '../../hooks/useWindowSize'

import styles from './DeliveryInfo.module.css'

export const deliveryWindowOptions = [
	{
		label: 'ASAP',
		value: 'asap',
	},
	{
		label: 'Anytime',
		value: 'anytime',
	},
	{
		label: 'Select a time',
		value: 'specify',
	},
]

// Gives dateString in the format of "YYYY-MM-DD"
export const getDateString = (date: Date) => {
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
}

// Get date a week from now.
const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 7)
const tomorrowsDateString = getDateString(tomorrow)

function DeliveryInfo() {
	const history = useHistory()
	
	const [space, setSpace] = useState<string | undefined>('')
	const { items } = useCart()
	const {
		name,
		address,
		postalCode,
		phone,
		email,
		city,
		deliveryDate,
		setDeliveryDate,
		deliveryWindow,
		setDeliveryWindow,
		deliveryTime,
		setDeliveryTime,
		setName,
		setAddress,
		setCity,
		setPhone,
		setPostalCode,
		setEmail,
	} = useCart()

	const windowSize = useWindowSize()

	useEffect(() => {
		if (items.length === 0)
			history.replace('/')
	}, [])

	const getFormIssues = () => {
		const issues = []

		const postalCodeRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

		if (!name) issues.push('Customer Name')
		if (!emailRegex.test(email)) issues.push('Email')
		if (!phoneRegex.test(phone)) issues.push('Phone Number')
		if (!address) issues.push('Address')
		if (!city) issues.push('City')
		if (!postalCodeRegex.test(postalCode)) issues.push('Postal Code')

		return issues
	}

	const formIssues = getFormIssues()
	const parseFormIssues = () => {
		const suffix = formIssues.length > 1 ? 's' : ''
		return `Please ensure the formatting is correct for the following field${suffix}.`
	}
	
	return (
		<div className={`limited ${styles.container} ${windowSize}`} style={{ paddingTop: space }}>
			<div className={styles.breadcrumb}>,
				<Breadcrumb path={['Home', 'Place Order', 'Delivery Info']} />
			</div>
			<Header setSpace={setSpace} />
			<div className={styles.deliveryInfoContainer}>
				<div className="section-title">Customer Info</div>
				{formIssues.length > 0 &&
					<>
						<div className={styles.issueText}>{parseFormIssues()}</div>
						<ul className={styles.issueList}>
							{formIssues.map((issue) => <li key={issue}>{issue}</li>)}
						</ul>
					</>
				}
				<div className={styles.inputRow}>
					<div className={styles.input}>
						<Input value={name} setValue={setName} placeholder="Customer Name" />
					</div>
					<div className={styles.input}>
						<Input value={email} setValue={setEmail} placeholder="Email" />
					</div>
					<div className={styles.input}>
						<Input value={phone} setValue={setPhone} placeholder="Phone Number" />
					</div>
				</div>
				<div className="section-title">Delivery Address</div>
				<div className={styles.inputRow}>
					<div className={styles.input}>
						<Input value={address} setValue={setAddress} placeholder="Address" />
					</div>
					<div className={styles.input}>
						<Input value={city} setValue={setCity} placeholder="City" />
					</div>
					<div className={styles.input}>
						<Input value={postalCode} setValue={setPostalCode} placeholder="Postal Code" />
					</div>
				</div>
				<div className="section-title">Delivery Time</div>
				<div className={styles.inputRow}>

					<div className={[styles.input, styles.deliveryWindow].join(' ')}>
						<select className='input wide' onChange={(e) => setDeliveryWindow(e.target.value)} value={deliveryWindow} style={{ height: '100%' }}>
							{deliveryWindowOptions.map(win => <option
								key={win.value}
								label={win.label}
							>{win.label}</option>)}
						</select>
					</div>

					<div className={styles.input}>
						<Input value={deliveryDate} min={tomorrowsDateString} setValue={setDeliveryDate} type='date' disabled={deliveryWindow !== 'specify'}/>
					</div>
					<div className={styles.input}>
						<Input value={deliveryTime || ''} setValue={setDeliveryTime} placeholder="Time" disabled={deliveryWindow !== 'specify'}/>
					</div>
				</div>
				<div className={styles.nextContainer}>
					<Anchor disabled={formIssues.length > 0} highlight label="NEXT" href="/orderReview" />
				</div>
			</div>
		</div>
	)
}

export default DeliveryInfo
