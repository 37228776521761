interface ConditionalClass {
  [key: string]: boolean;
}

/**
 * Takes an array of key-value pairs, the key becomes the class and the
 * value determines whether or not the class is rendered.
 *
 * @param absolute A string of absolute classes.
 * @param classes Objects with string-boolean key-value pairs.
 * @returns All truthy value pair key names.
 */
const classes = (absolute: string, classes: ConditionalClass) => {
	const conditionalClasses = Object.entries(classes).reduce((classList: string[], entry) => {
		const [key, value] = entry
		return value ? [...classList, key] : classList
	}, [])

	return [...conditionalClasses, absolute].join(' ')
}

export default classes
