import React from 'react'

import styles from './Anchor.module.css'
import classes from '../../utils/classes'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router'

export interface AnchorProps {
  className?: string;
  size?: 'mini';
	disabled?: boolean;
  button?: boolean;
  highlight?: boolean;
  label?: string;
  icon?: IconDefinition;
  href?: string;
  onClick?: Function;
}

function Anchor({
	className,
	size,
	disabled = false,
	button = false,
	highlight = false,
	label,
	href,
	icon,
	onClick,
}: AnchorProps) {
	const history = useHistory()

	const handleClick = async (event: React.MouseEvent) => {
		if (href && !onClick) {
			if (disabled) return false
			if (href.includes('http')) return window.location.href = href
			return history.push(href)
		}
		if (disabled) {
			event.preventDefault()
			return
		}
		if (onClick) {
			event.preventDefault()
			await onClick()
			// If href contains http or https, redirect page to href.
			if (href) {
				if (href.includes('http')) return window.location.href = href
				history.push(href)
			}
		}
	}

	return (
		<a
			onClick={handleClick}
			className={`${className} ${classes(styles.container, {
				[styles.mini]: size === 'mini',
				[styles.button]: button,
				[styles.highlight]: highlight,
				[styles.disabled]: disabled,
			})}`}
		>
			{icon && <FontAwesomeIcon style={{ fontSize: '1.5rem' }} icon={icon} />}
			{label && !icon && <>{label}</>}
		</a>
	)
}

export default Anchor
