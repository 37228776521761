import React, { useEffect, useState } from 'react'
import Anchor from '../../components/Anchor/Anchor'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import Header from '../../components/Header/Header'
import useCart from '../../contexts/useCart'
import { useWindowSize } from '../../hooks/useWindowSize'

import styles from './Complete.module.css'
  
function Order() {
	const { clear, setOrderNotes, setDeliveryDate, setDeliveryTime } = useCart()
	
	const [space, setSpace] = useState<string | undefined>('')
	const windowSize = useWindowSize()

	useEffect(() => {
		clear()
		setOrderNotes('')
		setDeliveryDate('')
		setDeliveryTime('')
	}, [])

	return (
		<div
			className={`limited ${styles.container} ${windowSize}`}
			style={{ paddingTop: space }}
		>
			<Breadcrumb path={['Home', 'Order Sent']} />
			<Header setSpace={setSpace} />
			<div className={styles.content}>
				<h2 className={styles.heading}>Your order has been sent to Jesa for approval!</h2>
				<p>
					<strong>*NOTE: </strong>
					<span>
            Your order has been placed, but it is still pending. For scheduling
            purposes, Jesa will review your order. She will get back to you
            within 24 hours to either approve your order, or to discuss any
            necessary date and/or time adjustments.*
					</span>
				</p>
				<p>
          You will receive an email with a copy of your order once approved.
          Payment will be received upon delivery. Cash, debit, and credit card
          all accepted.
				</p>
				<p>Thank you so much for choosing Jesa Sweet Tooth!</p>
				<div className={styles.anchorContainer}>
					<Anchor className={styles.toHome} highlight href='/' label="Home" />
				</div>
			</div>
		</div>
	)
}
  
export default Order
