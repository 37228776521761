import { Category, Ingredient } from '../../../../commonTypes/CustomCookieTypes'
import beam from '../beam'

interface IngredientsResponse {
	categories: Category[];
	ingredients: Ingredient[];
}

export const getIngredients = (): Promise<IngredientsResponse> => {
	return beam.get('/builder/ingredients')
}
