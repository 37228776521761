import React, { useState } from 'react'
import { useHistory } from 'react-router'
import Anchor from '../../components/Anchor/Anchor'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import Header from '../../components/Header/Header'
import Input from '../../components/Input/Input'
import MyOrder from '../../components/MyOrder/MyOrder'
import OrderItemGroup from '../../components/OrderItemGroup/OrderItemGroup'
import useCart from '../../contexts/useCart'
import { useWindowSize } from '../../hooks/useWindowSize'

import styles from './Order.module.css'
  
function Order() {
	const history = useHistory()
	const { items, orderNotes, setOrderNotes } = useCart()
	
	const [space, setSpace] = useState<string | undefined>('')
  
	const windowSize = useWindowSize()

	const handleNextStep = () => {
		if (items.length) {
			history.push('/deliveryInfo')
		}
	}

	return (
		<div className={`limited ${styles.container} ${windowSize}`} style={{ paddingTop: space }}>
			<Breadcrumb path={['Home', 'Place Order']} />
			<Header setSpace={setSpace} />
			<div className={styles.split}>
				<div>
					<OrderItemGroup title="Treats" />
				</div>
				<div className={styles.right}>
					<MyOrder />
					<div className="section-subheading">Allergies or Special Instructions</div>
					<Input dark size="textarea" value={orderNotes} setValue={setOrderNotes} placeholder="Eg. Chickpea and peanut allergy. Light on the icing for the muffin. " />
					<div className={styles.nextButton}>
						<Anchor highlight label="NEXT" onClick={handleNextStep} disabled={!items.length} />
					</div>
				</div>
			</div>
		</div>
	)
}
  
export default Order
