import React, { useState }  from 'react'
import { Product } from '../../../../commonTypes/StoreTypes'
import useCart from '../../contexts/useCart'
import AddToCartPopup from './AddToCartPopup'
import styles from './OrderItem.module.css'
  
interface OrderItemProps {
	product: Product
}

function OrderItem({ product }: OrderItemProps) {
	const { addItem } = useCart()
	const [showPopup, setShowPopup] = useState(false)
	
	const clickHandler = () => {
		if (product.variants.length === 1){
			return addItem({
				product: product,
				variant: product.variants[0],
				name: product.title,
				notes: '',
				quantity: 1
			})
		}
		
		setShowPopup(true)
	}
	
	return (
		<>
			<AddToCartPopup visible={showPopup} setVisible={setShowPopup} product={product}/>
			<div className={styles.container}>
				<div className={styles.preview} onClick={clickHandler}>
					<img className={styles.image} src={product.image?.src} />
				</div>
				<div className={styles.name}>{product.title}</div>
				<div className={styles.price}>{product.variants[0].price}</div>
			</div>
		</>
	)
}
  
export default OrderItem
