import React from 'react'
import Anchor from '../Anchor/Anchor'

import CustomCookieImage from '../../assets/custom-cookie.jpg'

import styles from './CustomOrderItem.module.css'

function CustomOrderItem() {
	return (
		<div className={styles.container}>
			<div className={styles.preview}>
				<div className={styles.hover}>
					<Anchor
						highlight
						size="mini"
						label="Build Cookie"
						href="/build" />
				</div>
				<img className={styles.image} src={CustomCookieImage} />
			</div>
			<div className={styles.name}>Build-A-Cookie</div>
		</div>
	)
}

export default CustomOrderItem
