import React, { useEffect, useMemo, useState } from 'react'
import CustomCookie from '../../components/CustomCookie/CustomCookie'
import Dropdown, { DropdownOption } from '../../components/Dropdown/Dropdown'
import Header from '../../components/Header/Header'
import Input from '../../components/Input/Input'
import OptionContainer from '../../components/OptionContainer/OptionContainer'
import OrderTotal from '../../components/OrderTotal/OrderTotal'
import Selector, { Option } from '../../components/Selector/Selector'
import VeganImage from '../../assets/vegan.svg'
import GlutenImage from '../../assets/gluten-free.png'
import styles from './BuildACookie.module.css'
import Anchor from '../../components/Anchor/Anchor'
import useCart from '../../contexts/useCart'
import { Category, CategoryName, Ingredient } from '../../../../commonTypes/CustomCookieTypes'
import { useHistory } from 'react-router'
import { useWindowSize } from '../../hooks/useWindowSize'
import useCustomCookie, { CustomCookieProvider } from '../../contexts/useCustomCookie'
import { Variant, Product } from '../../../../commonTypes/StoreTypes'

const categoryToOptions = (
	ingredients: Ingredient[],
	categories: Category[],
	categoryName: CategoryName
) => {
	const category = categories.find(({ category: reference }) => {
		return (reference as CategoryName) === categoryName
	})

	const { min, max } = category as Category

	return ingredients
		.filter(({ category: reference }) => categoryName === reference)
		.map(({ name, id, restrictions, color }) => {
			return {
				name,
				value: id,
				disabled: false,
				selected: false,
				min,
				max,
				restrictions,
				color,
				suffixImages: [
					restrictions.includes('vegan') ? VeganImage : null,
					restrictions.includes('gluten') ? GlutenImage : null,
				].filter((value) => value),
			} as Option
		})
}

const abideIngredientByRestriction = (
	restrictions: Option[],
	ingredients: Option[]
) => {
	const isUserGlutenFree = restrictions.find(
		({ value }) => value === 'gluten'
	)?.selected
	const isUserVegan = restrictions.find(
		({ value }) => value === 'vegan'
	)?.selected

	return ingredients.map((dough) => {
		const isIngredientGlutenFree = dough.restrictions?.includes('gluten')
		const isIngredientVegan = dough.restrictions?.includes('vegan')

		const restricted =
      !!(isUserGlutenFree && isIngredientGlutenFree === false) ||
      !!(isUserVegan && isIngredientVegan === false)

		return {
			...dough,
			disabled: restricted,
			selected: restricted ? false : dough.selected,
		}
	})
}

function BuildACookieInternal() {
	const history = useHistory()
	const { addItem } = useCart()
	
	const { customCookie, ingredients, categories } = useCustomCookie()
	
	const numberOfCookiesOptions = useMemo(() => {
		const ops = customCookie.options.find(p => p.name === 'Number') || { values: [] }
		
		return ops.values.map(option => ({
			active: true,
			label: `${option} Cookies`,
			value: option,
		}))
	}, [])
	
	const [dietaryRestrictions, setDietaryRestrictions] = useState<Option[]>([
		{
			name: 'Vegan',
			disabled: false,
			selected: false,
			value: 'vegan',
			suffixImages: [VeganImage],
		},
		{
			name: 'Gluten Free',
			disabled: false,
			selected: false,
			value: 'gluten',
			suffixImages: [GlutenImage],
		},
	])
	
	const [numberOfCookies, setNumberOfCookies] = useState<DropdownOption>(
		numberOfCookiesOptions[0]
	)

	const [dough, setDough] = useState<Option[]>(
		categoryToOptions(ingredients, categories, 'dough')
	)
	const [toppings, setToppings] = useState<Option[]>(
		categoryToOptions(ingredients, categories, 'topping')
	)
	const [stuffing, setStuffing] = useState<Option[]>(
		categoryToOptions(ingredients, categories, 'stuffing')
	)
	const [lettering, setLettering] = useState<Option[]>(
		categoryToOptions(ingredients, categories, 'lettering')
	)
	
	const getVariant = () => {
		const selectedLettering = lettering.find(val => val.selected)
		const selectedStuffing = stuffing.find(val => val.selected)
		const selectedNumber = numberOfCookies?.value

		return customCookie.variants.find(v => {
			return v.option1 === selectedNumber
				&& v.option2 === (selectedStuffing ? 'with-stuffing' : 'no-stuffing')
				&& v.option3 === (selectedLettering ? 'with-lettering' : 'no-lettering')
		})
	}
	
	//recalcualte the subtotal when the options change
	const subtotal = useMemo<number>(() => {
		const cookieVariant = getVariant()
		return parseFloat(cookieVariant?.price || '0') * 100
	}, [stuffing, lettering, numberOfCookies, customCookie])

	const [letters, setLetters] = useState<string>('')
	const [specialInstructions, setSpecialInstructions] = useState<string>('')
	
	useEffect(() => {
		if (!lettering.some(({ selected }) => selected))
			setLetters('')
	}, [lettering])

	const [space, setSpace] = useState<string | undefined>('')

	useEffect(() => {
		setDough((dough) =>
			abideIngredientByRestriction(dietaryRestrictions, dough)
		)
		setToppings((topping) =>
			abideIngredientByRestriction(dietaryRestrictions, topping)
		)
		setStuffing((stuffing) =>
			abideIngredientByRestriction(dietaryRestrictions, stuffing)
		)
		setLettering((lettering) =>
			abideIngredientByRestriction(dietaryRestrictions, lettering)
		)
	}, [dietaryRestrictions])
	
	const windowSize = useWindowSize()

	const getIngredientByOption = ({ value }: Option) => {
		return ingredients.find(({ id }) => value === id)
	}

	const getSelectedLetteringColor = () => {
		const selectedLettering = lettering.find(({ selected }) => selected)

		if (!selectedLettering) return
		return selectedLettering.color
	}

	const getSelectedIngredientsByCategory = (category: CategoryName) => {
		let categoryState
		if (category === 'dough') categoryState = dough
		if (category === 'stuffing') categoryState = stuffing
		if (category === 'topping') categoryState = toppings
		if (category === 'lettering') categoryState = lettering
		return (categoryState || []).filter(({ selected }) => selected)
	}

	const getSelectedIngredients = () => {
		return [...dough, ...toppings, ...stuffing, ...lettering]
			.filter(({ selected }) => selected)
			.map(getIngredientByOption)
			.filter((result) => result) as Ingredient[]
	}

	const shouldAddLetters = () => {
		return lettering.findIndex(({ selected }) => selected) !== -1
	}

	const getQuantityErrors = () => {
		const minimums: any = ['dough', 'stuffing', 'topping', 'lettering'].reduce((accumulator, name) => {
			const { min } = categories.find(({ category }) => category === name) || {}
			return { ...accumulator, [name]: min }
		}, {})

		const errors = {
			dough: `You need to select at least ${minimums.dough} dough.`,
			stuffing: `You need to select at least ${minimums.stuffing} stuffing.`,
			topping: `You need to select at least ${minimums.topping} toppings.`,
			lettering: 'If you\'d like to customize your cookie with lettering, please let us know what to write!',
		}

		return {
			dough: getSelectedIngredientsByCategory('dough').length < minimums.dough ? errors.dough : '',
			stuffing: getSelectedIngredientsByCategory('stuffing').length < minimums.stuffing ? errors.stuffing : '',
			topping: getSelectedIngredientsByCategory('topping').length < minimums.topping ? errors.topping : '',
			lettering: letters.length === 0 && shouldAddLetters() ? errors.lettering : '',
		}
	}

	const hasErrors = () => {
		return Object.values(getQuantityErrors()).some((hasError) => hasError)
	}

	const submit = () => {
		const quantity = numberOfCookies.value as number
		
		let notes = getSelectedIngredients().reduce((curr, acc) => {
			return `${curr}\n${acc.name}`
		}, '')
		
		if (shouldAddLetters()){
			notes = `${notes}\nLetters: ${letters} (${lettering.find(v => v.selected)?.name || ''})`
		}
		
		notes = `${notes}\nNotes:${specialInstructions}`
		
		addItem({
			name: `Custom cookie (${quantity} pack)`,
			notes,
			product: customCookie as Product,
			variant: getVariant() as Variant,
			quantity: 1,
		})

		history.push('/')
	}

	return (
		<>
			<div
				className={`limited ${styles.container} ${windowSize}`}
				style={{ paddingTop: space }}
			>
				<Header setSpace={setSpace} />
				<h2 className="page-heading" style={{ textAlign: 'center' }}>
					Jesa&apos;s Build-A-Cookie
				</h2>
				<div className={styles.builder}>
					<div className={styles.cookieContainer}>
						<CustomCookie
							text={letters}
							textColor={getSelectedLetteringColor() || '#FFFFFF'}
						/>
					</div>
					<div className={styles.options}>
						<div className={styles.row}>
							<OptionContainer headingText="Number of Cookies">
								<Dropdown
									options={numberOfCookiesOptions}
									setValue={setNumberOfCookies}
									value={numberOfCookies}
								/>
							</OptionContainer>
							<OptionContainer columns={2} headingText="Dietary Restrictions">
								<Selector
									setOptions={setDietaryRestrictions}
									options={dietaryRestrictions}
								/>
							</OptionContainer>
						</div>
						<div className={styles.row}>
							<OptionContainer quantityError={getQuantityErrors().dough} columns={3} headingText="Dough">
								<Selector radio setOptions={setDough} options={dough} />
							</OptionContainer>
						</div>
						<div className={styles.row}>
							<OptionContainer quantityError={getQuantityErrors().topping} columns={5} headingText="Toppings (max. 5)">
								<Selector setOptions={setToppings} options={toppings} />
							</OptionContainer>
						</div>
						<div className={styles.row}>
							<OptionContainer quantityError={getQuantityErrors().stuffing} columns={5} headingText="Stuffing">
								<Selector radio setOptions={setStuffing} options={stuffing} />
							</OptionContainer>
						</div>
						<div className={styles.row}>
							<OptionContainer quantityError={getQuantityErrors().lettering} headingText="Lettering (max. 6 characters)">
								<div className={styles.lettering}>
									<div className={styles.internalGroup}>
										<div className={styles.internalTitle}>Characters:</div>
										<Input
											disabled={!lettering.some(({ selected }) => selected)}
											allCaps
											size="mini"
											value={letters}
											setValue={setLetters}
										/>
									</div>
									<div className={styles.internalGroup}>
										<div className={styles.internalTitle}>Flavour:</div>
										<div className={styles.flavours}>
											<Selector
												radio
												options={lettering}
												setOptions={setLettering}
											/>
										</div>
									</div>
								</div>
							</OptionContainer>
						</div>
						<div className={styles.row}>
							<OptionContainer headingText="Allergies or Special Instructions">
								<Input
									size="textarea"
									value={specialInstructions}
									setValue={setSpecialInstructions}
									placeholder="Eg. Chickpea and peanut allergy, heavy on the chocolate stuffing!"
								/>
							</OptionContainer>
						</div>
						<div className={styles.totalContainer}>
							<OrderTotal
								subtotal={subtotal}
								hst={subtotal * 0.13}
								containerWidth="50%"
							/>
						</div>
						{hasErrors() && <div className={styles.error}>
								There seem to be an issue with your order, please make sure you have selected all the required fields.
						</div>}
						<div className={styles.submitButton}>
							<Anchor
								disabled={hasErrors()}
								highlight
								label="Add to Cart"
								href="/"
								onClick={submit}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

function BuildACookie(){
	return <CustomCookieProvider>
		<BuildACookieInternal/>
	</CustomCookieProvider>
}

export default BuildACookie
