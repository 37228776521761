import React, { useEffect, useRef, useState } from 'react'

import Navigation from '../Navigation/Navigation'

import styles from './Header.module.css'
import { useWindowSize, windowSizes } from '../../hooks/useWindowSize'

interface HeaderProps {
  setSpace: React.Dispatch<React.SetStateAction<string | undefined>>
}

function Header({ setSpace }: HeaderProps) {
	const [navExpanded, setNavExpanded] = useState<boolean>(false)

	const headerRef = useRef<HTMLDivElement>(null)
	const windowSize = useWindowSize()

	useEffect(() => {
		const { current: header } = headerRef
		if (!header) return
		setSpace(`calc(${header.getBoundingClientRect().height}px + 4rem)`)
	}, [windowSize, headerRef.current])

	const toggleExpansion = () => {
		setNavExpanded((previous) => !previous)
	}

	return (
		<header className={styles.container}>
			<div ref={windowSize == windowSizes.desktop ? headerRef : undefined} className={`limited ${styles.external}`}>
				<div ref={windowSize != windowSizes.desktop ? headerRef : undefined} className={styles.internal}>
					<button className={styles.hamburger} onClick={toggleExpansion} />
					<a href="/">
						<div className={styles.logo} />
					</a>
				</div>
				<Navigation expanded={navExpanded} />
			</div>
		</header>
	)
}

export default Header
