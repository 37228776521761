import React from 'react'

import styles from './Input.module.css'

interface InputProps {
  size?: 'mini' | 'normal' |'big' | 'textarea'
	dark?: boolean;
  allCaps?: boolean;
  value: string;
  setValue: React.Dispatch<string>;
  placeholder?: string;
	disabled?: boolean;
	type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'textarea' | 'date' | 'time' | 'datetime-local' | 'file'
	min?: string;
}

function Input({ size = 'normal', dark, allCaps, value, setValue, placeholder, disabled = false, type = 'text', min }: InputProps) {
	const handleInput = ({ target: { value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (size === 'mini' && value.length > 6) return
		else setValue(allCaps ? value.toUpperCase() : value)
	}

	const attributes = {
		className: `${styles.input} ${styles[size]} ${dark ? styles.dark : ''}`,
		onChange: handleInput,
		value,
		placeholder,
		disabled,
		type,
		min
	}
  
	return size === 'textarea' ? <textarea {...attributes} /> : <input {...attributes } />
}

export default Input
