import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Product, Variant } from '../../../../commonTypes/StoreTypes'
import useCart from '../../contexts/useCart'
import { removeItemAtIndex } from '../../utils/arrayUtils'
import Anchor from '../Anchor/Anchor'
import s from './AddToCartPopup.module.css'
import MultiOptionRow from './MultiOptionRow'

interface Props {
	product: Product,
	setVisible(v: boolean): void
}

const MAX_NUMBER_OF_ROWS = 3

export default function MultiOption({ product, setVisible }: Props) {
	const { addItem } = useCart()
	
	const [selectedVariants, setSelectedVariants] = useState<Variant[]>([
		product.variants[0]
	])
	
	const addRow = () => {
		if (selectedVariants.length < MAX_NUMBER_OF_ROWS){
			setSelectedVariants(curr => [...curr, product.variants[0]])
		}
	}
	
	const removeRow = (index:number) => setSelectedVariants(curr => removeItemAtIndex(index, curr))
	
	const setRowVariant = (index:number, variant:Variant) => setSelectedVariants(curr => {
		const newVariants = [...curr]
		newVariants[index] = variant
		return newVariants
	})

	const addToCart = () => {
		let notes = ''
		
		selectedVariants.forEach((v) => {
			notes = `${notes}\n *${v.option1 || ''} ${v.option2 || ''} ${v.option3 || ''}`
		})

		addItem({
			name: product.title,
			quantity: 1,
			notes,
			product,
			variant: product.variants[0]
		})

		setVisible(false)
	}

	return (
		<div className={s.story}>
			{selectedVariants.map((r, idx) => <MultiOptionRow
				product={product}
				remove={() => removeRow(idx)}
				row={r}
				setVariant={v => setRowVariant(idx, v)}
				key={idx}
				index={idx}
			/>)}
			
			<div className={s.story}>
				<button className="button plain" onClick={addRow} disabled={selectedVariants.length > MAX_NUMBER_OF_ROWS - 1}>
					<FontAwesomeIcon icon={faPlus}/> Add another row
				</button>
			</div>

			<div className={s.story}>
				<Anchor
					button
					highlight
					className="wide"
					label='Add to order'
					onClick={addToCart}
				/>
			</div>
		</div>
	)
}
