import React, { useState } from 'react'
import useCart from '../../contexts/useCart'
import CartItem from '../CartItem/CartItem'
import styles from './MyOrder.module.css'
import Anchor from '../Anchor/Anchor'
import { useHistory } from 'react-router'

interface MyOrderProps {
	hideTitle?: boolean;
	showTotal?: boolean;
}

function MyOrder({ hideTitle, showTotal }: MyOrderProps) {
	const { items, subtotal, tax, total, checkout } = useCart()

	const [submitted, setSubmitted] = useState<boolean>(false)
	const [orderLabel, setOrderLabel] = useState<string>('Place Order')
	const [errorMsg, setErrorMsg] = useState<string>('')

	const history = useHistory()

	const handleOrderSubmit = () => {
		if (submitted) return
		setSubmitted(true)

		setOrderLabel('Submitting Order...')

		checkout().then(() => {
			history.push('/submit')
		}).catch(() => {
			setErrorMsg('An error occurred while submitting your order. Please review your order details and try again.')
			setOrderLabel('Place Order')
			setSubmitted(false)
		})
			

	}

	return (
		<div className={styles.container}>
			{!hideTitle && <div className="section-heading">My Order</div>}
			<div className={styles.cart}>
				{items.filter(({ quantity }) => quantity).length === 0 &&
					<div className={styles.emptyState}>There are no items in the cart.</div>
				}
				{items.map((item, idx) => {
					return <CartItem
						key={idx}
						index={idx}
						item={item}
					/>
				})}
					
				{showTotal && (
					<div className={styles.total}>
						<div className={styles.row}>
							<div className={styles.rowName}>Subtotal:</div>
							<div className={styles.dataValue}>C${(subtotal).toFixed(2)}</div>
						</div>
						<div className={styles.row}>
							<div className={styles.rowName}>HST:</div>
							<div className={styles.dataValue}>C${(tax/100).toFixed(2)}</div>
						</div>
						<div className={styles.row}>
							<div className={styles.rowName}>Final Total:</div>
							<div className={styles.dataValue}>C${((total) / 100).toFixed(2)}</div>
						</div>
					</div>
				)}
			</div>
			{showTotal && <div className={styles.submitButton}>
				<Anchor
					href="/orderReview"
					onClick={handleOrderSubmit}
					label={orderLabel}
					highlight />
				<div className={styles.errorMsg}>
					{errorMsg}
				</div>
			</div>}
		</div>
	)
}

export default MyOrder
