import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { CartProvider } from './contexts/useCart'
import BuildACookie from './pages/BuildACookie/BuildACookie'
import Order from './pages/Order/Order'
import DeliveryInfo from './pages/DeliveryInfo/DeliveryInfo'
import Review from './pages/Review/Review'
import Complete from './pages/Complete/Complete'
import { ProductsProvider } from './contexts/useProducts'
import PopupProvider from './components/Popup/PopupProvider'

function App() {
	return (
		<PopupProvider>
			<ProductsProvider>
				<CartProvider>
					<div className='appWrapper'>
						<Router>
							<Switch>
								<Route exact path="/" component={Order} />
								<Route path="/build" component={BuildACookie} />
								<Route path="/deliveryInfo" component={DeliveryInfo} />
								<Route path="/orderReview" component={Review} />
								<Route path="/submit" component={Complete} />
							</Switch>
						</Router>
					</div>
				</CartProvider>
			</ProductsProvider>
		</PopupProvider>
	)
}

export default App
