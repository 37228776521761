import { useEffect, useState } from 'react'


export enum windowSizes {
	desktop = 'desktop',
	tablet = 'tablet',
	mobile = 'mobile',
}

export function useWindowSize() {

	const [screenSize, setScreenSize] = useState<windowSizes>()

	useEffect(() => {
		listener()
		window.addEventListener('resize', listener)
		return () => window.removeEventListener('resize', listener)
	}, [])

	const listener = () => {
		const currentScreenSize = window.innerWidth
		if (currentScreenSize > 1200) {
			setScreenSize(windowSizes.desktop)

		} else if (currentScreenSize > 950) {
			setScreenSize(windowSizes.tablet)
		}
		else {
			setScreenSize(windowSizes.mobile)
		}
	}

	return screenSize
}
