import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

import styles from './Dropdown.module.css'

export interface DropdownOption {
	value: string | number;
	label: string;
	active: boolean;
	text?: string;
}

interface DropdownProps {
	value: DropdownOption | undefined,
	setValue(val: DropdownOption): any,
	options: DropdownOption[],
}

function Dropdown({ value, setValue, options }: DropdownProps) {
	const [open, setOpen] = useState<boolean>(false)
	const toggleOpen = () => setOpen(previous => !previous)

	const handleSelect = setValue

	return (
		<div className={`${styles.container} ${open ? styles.open : ''}`} onClick={toggleOpen}>
			<div className={styles.display}>
				<div className={styles.preview}>{value?.label || 'Unknown'}</div>
				<FontAwesomeIcon icon={faChevronDown} className={styles.icon} />
			</div>
			{open && <div className={styles.content}>
				{
					options.filter(({ active }) => active).map((option) => {
						const { value, label } = option
						return (
							<div
								onClick={() => handleSelect(option)}
								className={styles.option}
								key={value}>{label}</div>
						)
					})
				}
			</div>}
		</div>
	)
}

export default Dropdown
