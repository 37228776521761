import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Product, Variant } from '../../../../commonTypes/StoreTypes'
import s from './AddToCartPopup.module.css'

interface Props{
	row: Variant,
	setVariant(variant: Variant): void
	remove(): void,
	product: Product,
	index: number
}

export default function MultiOptionRow({ row, setVariant, remove, product, index }:Props) {
	//find a variant that matches the new configuration and select it
	const changeHandler = ({ target }:any) => {
		const option1 = target.name === '1' ? target.value : row.option1
		const option2 = target.name === '2' ? target.value : row.option2
		const option3 = target.name === '3' ? target.value : row.option3
		
		const newVariant = product.variants.find((p) => (
			p.option1 === option1 &&
			p.option2 === option2 &&
			p.option3 === option3
		))
		
		if (newVariant){
			setVariant(newVariant)
		}
	}
	
	return (
		<div className={s.MultiOptionRow}>
			<div className={s.numberContainer}>
				{index + 1}.
			</div>
			
			<div className={s.notNumberContainer}>
				{product.options.map((opt, idx) => <div className={s.selectWrapper} key={opt.id}>
					<select
						className={`input wide ${s.selector}`}
						value={(row as any)[`option${idx+1}`]}
						onChange={changeHandler}
						name={`${idx+1}`}
					>
						{opt.values.map(val => <option key={val} value={val}>
							{val}
						</option>)}
					</select>
					
					{idx < product.options.length - 1 && <div className={s.verticalLine}/>}
				</div>)}
			</div>
			
			<div className={`${s.numberContainer} ${s.center}`}>
				{index !== 0 && <button className="button wide plain" onClick={remove}>
					<FontAwesomeIcon icon={faTimes} />
				</button>}
			</div>
		</div>
	)
}
