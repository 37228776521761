import React, { useContext, createContext, useState, useMemo, useCallback } from 'react'
import { StoreCartItem } from '../../../commonTypes/StoreTypes'
import { placeOrder } from '../api/routes/orders'
import { getDateString } from '../pages/DeliveryInfo/DeliveryInfo'

type AddItemParams = Omit<StoreCartItem, 'price'>

const Context = createContext<{
	items: StoreCartItem[],
	subtotal: number,
	total: number
	tax: number,
	
	orderNotes: string,
	setOrderNotes(val:string): void,
	
	deliveryWindow: string,
	deliveryDate: string
	deliveryTime: string,
	setDeliveryDate(val:string): void,
	setDeliveryWindow(val:string): void,
	setDeliveryTime(val:string): void,
	
	email: string,
	setEmail(val: string): void,
	name: string,
	setName(val: string): void,
	phone: string,
	setPhone(val: string): void,
	address: string,
	setAddress(val: string): void,
	city: string,
	setCity(val: string): void,
	postalCode: string,
	setPostalCode(val: string): void,
	
	addItem(item:AddItemParams): void,
	removeItemAtIndex(index: number): void,
	
	clear(): void,
	
	checkout(): Promise<void>
		}>(null as any)


interface ProviderProps{
	children:any
}

export const CartProvider = ({ children }: ProviderProps) => {
	const [items, setItems] = useState<StoreCartItem[]>([])
	
	const subtotal = useMemo(() => items.reduce((acc, curr) => acc + curr.price, 0), [items])
	const tax = useMemo(() => subtotal * 0.13, [subtotal])
	const total = useMemo(() => tax + subtotal, [subtotal, tax])

	const [orderNotes, setOrderNotes] = useState('')
	
	const [deliveryWindow, setDeliveryWindow] = useState(() => {
		const dt = new Date()
		dt.setDate(dt.getDate() + 7)
		return getDateString(dt)
	})
	
	const [deliveryDate, setDeliveryDate] = useState('')
	const [deliveryTime, setDeliveryTime] = useState('')
	
	const [email, setEmail] = useState('')
	const [name, setName] = useState('')
	
	const [phone, setPhone] = useState('')
	const [address, setAddress] = useState('')
	const [city, setCity] = useState('')
	const [postalCode, setPostalCode] = useState('')
	
	const addItem = useCallback((item:AddItemParams) => setItems(curr => {
		const newItem = {
			...item,
			price: parseFloat(item.variant.price) * item.quantity
		}
		
		return [...curr, newItem]
	}), [])
	
	const removeItemAtIndex = useCallback((index:number) => setItems(curr => [...curr].filter((_p, idx) => idx !== index)), [])
	
	const clear = () => setItems([])
	
	const checkout = async () => {
		let fullNotes = orderNotes ? `Order notes: \n${orderNotes}` : ''
		
		items.forEach(r => {
			fullNotes = `${fullNotes}\n\n\n${r.name}:${r.notes}`
		})
		
		return placeOrder({
			address,
			city,
			deliveryDate,
			deliveryTime,
			deliveryWindow,
			email,
			items,
			name,
			orderNotes: fullNotes,
			phone,
			postalCode,
		})
	}
	
	return <Context.Provider value={{
		items,
		total,
		subtotal,
		tax,
		orderNotes,
		setOrderNotes,
		deliveryDate,
		setDeliveryDate,
		deliveryTime,
		deliveryWindow,
		setDeliveryTime,
		setDeliveryWindow,
		name,
		setName,
		email,
		setEmail,
		phone,
		setPhone,
		postalCode,
		setPostalCode,
		address,
		setAddress,
		city,
		setCity,
		addItem,
		removeItemAtIndex,
		checkout,
		clear,
	}}>
		{ children }
	</Context.Provider>
}

const useCart = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useCart outside provider!')
	}
	return val
}

export default useCart
