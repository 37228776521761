import React from 'react'

import CutoutCookie from '../../assets/cutout-cookie.png'

import styles from './CustomCookie.module.css'

interface CustomCookieProps {
	text: string;
	textColor: string;
}

function CustomCookie({ text, textColor }: CustomCookieProps) {
	return (
		<div className={styles.container}>
			<div className={styles.text} style={{ color: textColor }}>{text}</div>
			<div className={styles.offsetText} style={{ color: textColor }}>{text}</div>
			<img src={CutoutCookie} />
		</div>
	)
}

export default CustomCookie
