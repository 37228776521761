import React from 'react'

import styles from './OptionContainer.module.css'

interface OptionContainerProps {
  headingText: string;
	quantityError?: string;
  children: React.ReactNode | React.ReactText;
	columns?: number;
}

function OptionContainer({ headingText, quantityError, children, columns }: OptionContainerProps) {
	return (
		<div className={styles.container}>
			<div className={styles.heading}>{headingText}</div>
			{quantityError && <div className={styles.error}>{quantityError}</div>}
			<div className={`${columns ? styles.grid : ''}`} style={{
				gridTemplateColumns: `repeat(${columns}, minmax(auto, 1fr))`
			}}>
				{children}
			</div>
		</div>
	)
}

export default OptionContainer
