import React from 'react'
import useProducts from '../../contexts/useProducts'
import CustomOrderItem from '../CustomOrderItem/CustomOrderItem'
import OrderItem from '../OrderItem/OrderItem'

import styles from './OrderItemGroup.module.css'

interface OrderItemGroupProps {
  title: string;
}

function OrderItemGroup({ title }: OrderItemGroupProps) {
	const { products } = useProducts()
	
	return (
		<div className={styles.container}>
			<div className="section-heading">{title}</div>
			<div className={styles.items}>
				<CustomOrderItem />
				{products.map((product) => {
					return <OrderItem
						key={product.id}
						product={product}
					/>
				})}
			</div>
		</div>
	)
}

export default OrderItemGroup
