import React, { useMemo, useState } from 'react'
import { Product } from '../../../../commonTypes/StoreTypes'
import useCart from '../../contexts/useCart'
import Anchor from '../Anchor/Anchor'
import s from './AddToCartPopup.module.css'

interface Props{
	product: Product,
	setVisible(v:boolean): void
}

export default function OneOption({ product, setVisible }:Props) {
	
	const [values, setValues] = useState(() => {
		const valueMap:any = {}
		product.options[0].values.forEach(v => valueMap[v] = false)
		return valueMap
	})
	
	const toggleValue = (val:string) => setValues((curr:any) => ({ ...curr, [val]: !curr[val] }))
	
	const numberSelected = useMemo(() => Object.values(values).reduce((acc, curr) => {
		return curr ? (acc as number) + 1 : acc
	}, 0), [values]) as number
	
	const { addItem } = useCart()
	
	const addToCart = () => {
		let notes = `${product.title}:`
		
		Object.keys(values).map((v) => {
			if (values[v]){
				notes = `${notes}\n* ${v}`
			}
		})
		
		addItem({
			name: product.title,
			quantity: 1,
			notes,
			product,
			variant: product.variants[0]
		})
		
		setVisible(false)
	}
	
	return (
		<div className={s.story}>
			<b>Select {product.options[0].name}:</b>
			{Object.keys(values).map(val => <div
				key={val}
				className={`${s.optionCheckRow} ${numberSelected > 2 && !values[val] ? s.disabled : ''}`}
				onClick={() => {
					if (numberSelected > 2 && !values[val]){
						return
					}
					toggleValue(val)
				}}
			>
				<input type="checkbox" name={val} checked={values[val]} className={s.check} readOnly/>
				<span className={s.optionCheckLabel}>{val}</span>
			</div>)}
			
			<div className={s.story}>
				<Anchor
					button
					highlight
					className="wide"
					label='Add to order'
					onClick={addToCart}
					disabled={numberSelected === 0}
				/>
			</div>
		</div>
	)
}
