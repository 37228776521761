import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import CustomerInfo from '../../components/CustomerInfo/CustomerInfo'
import Header from '../../components/Header/Header'

import MyOrder from '../../components/MyOrder/MyOrder'
import useCart from '../../contexts/useCart'
import { useWindowSize } from '../../hooks/useWindowSize'

import styles from './Review.module.css'
  
function Order() {
	const history = useHistory()
	
	const { items, orderNotes, deliveryDate, deliveryTime, deliveryWindow } = useCart()
	const [space, setSpace] = useState<string | undefined>('')

	const windowSize = useWindowSize()

	const parseDeliveryDateTime = () => {
		return `${deliveryWindow}${deliveryWindow === 'specify' && ': ' + deliveryDate + ' ' + deliveryTime || ''}`
	}

	useEffect(() => {
		if (items.length === 0) {
			history.replace('/')
		}
	}, [items.length])

	const getCustomerDetails = () => {
		const { name, email, phone } = useCart()
		return [name, email, phone]
	}

	const getFullAddress = () => {
		const { address, city, postalCode } = useCart()
		return [address, city, postalCode]
	}
	
	return (
		<div
			className={`limited ${styles.container} ${windowSize}`}
			style={{ paddingTop: space }}
		>
			<Breadcrumb
				path={[
					'Home',
					'Place Order',
					'Date',
					'Treats',
					'Delivery Info',
					'Order Review',
				]}
			/>
			<Header setSpace={setSpace} />
			<div className={styles.split}>
				<div className={styles.customerInfo}>
					<CustomerInfo title={'Customer Info'} data={getCustomerDetails()} />
					<CustomerInfo title={'Delivery Address'} data={getFullAddress()} />
					<CustomerInfo title={'Delivery Date'} data={[parseDeliveryDateTime()]} />
					<CustomerInfo title={'Allergies/Special Instructions'} data={[orderNotes != '' ? orderNotes : 'No allergies or special instructions']} />
				</div>
				<div>
					<div className={styles.quantityDisplay}>
						Items ({items.length})
					</div>
					<MyOrder hideTitle={true} showTotal={true} />
				</div>
			</div>
		</div>
	)
}
  
export default Order
