import React, { useContext, createContext, useEffect, useState } from 'react'
import { Page } from 'react-ui-scaffold'
import { Category, Ingredient } from '../../../commonTypes/CustomCookieTypes'
import { Product } from '../../../commonTypes/StoreTypes'
import { getIngredients } from '../api/routes/ingredients'
import { getCustomCookie } from '../api/routes/products'

const Context = createContext<{
	customCookie: Product
	isLoading: boolean,
	ingredients: Ingredient[],
	categories: Category[]
}>(null as any)


interface ProviderProps{
	children:any
}

export const CustomCookieProvider = ({ children }: ProviderProps) => {
	const [customCookie, setCustomCookie] = useState<Product>()
	const [isLoading, setIsLoading] = useState(true)
	
	const [ingredients, setIngredients] = useState<Ingredient[]>([])
	const [categories, setCategories] = useState<Category[]>([])
	
	useEffect(() => {
		Promise.all([
			getCustomCookie(),
			getIngredients()
		]).then(([newCookie, newIngredients]) => {
			setCustomCookie(newCookie)
			setIngredients(newIngredients.ingredients)
			setCategories(newIngredients.categories)
		}).finally(() => setIsLoading(false))
	}, [])
	
	//#TODO refactor so the context doesn't have to handel any UI/loading state
	if (isLoading){
		return <Page loading/>
	}

	return <Context.Provider value={{
		isLoading,
		customCookie: customCookie as Product,
		ingredients,
		categories,
	}}>
		{ children }
	</Context.Provider>
}

const useCustomCookie = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useCustomCookie outside provider!')
	}
	return val
}

export default useCustomCookie
